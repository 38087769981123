<template>
    <div>
        <div class="policybox">
            <h1>Política de privacidad</h1>
            <div class=WordSection1 style='layout-grid:15.6pt'>

                <p class=MsoNormal align=center style='text-align:center'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>&nbsp;</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Digital Moneyboxmx </span></b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>es
                        una aplicación de servicios financieros en línea lanzada por <b>Digital
                            Moneyboxmx, S.A. de C.V., SOFOM, E.N.R.</b> Company. Como SOFOM
                        (entidad
                        financiera diversificada) autorizada y compatible, <b>Digital
                            Moneyboxmx, S.A.
                            de C.V., SOFOM, E.N.R. </b>Company está estrictamente
                        supervisada por el
                        regulador financiero mexicano para garantizar que todos los servicios
                        financieros que brinda cumplan con las leyes y regulaciones pertinentes.
                        Nos
                        adherimos a los principios de transparencia, equidad y seguridad y
                        estamos
                        comprometidos a brindar a los usuarios soluciones de préstamos
                        confiables y que
                        cumplan con las normas. Al utilizar nuestra plataforma, cada transacción
                        de los
                        usuarios está protegida por la ley y también estamos comprometidos a
                        proteger
                        completamente la seguridad financiera y la privacidad personal de los
                        usuarios.
                        Elija la empresa <b>Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R.</b>
                        y elija
                        servicios financieros profesionales confiables.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>&nbsp;</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>El
                        desarrollador de Digital Moneyboxmx es <b>Digital Moneyboxmx, S.A. de
                            C.V.,
                            SOFOM, E.N.R.</b> Nosotros, Digital Moneyboxmx (en lo sucesivo,
                        &quot;nosotros&quot;), respetamos y protegemos la privacidad personal de
                        todos
                        los usuarios que utilizan nuestros servicios. Con el fin de brindarle
                        servicios
                        más precisos y personalizados, utilizaremos y almacenaremos su
                        información personal
                        de acuerdo con esta Política de Privacidad, y trataremos esta
                        información con
                        un alto grado de diligencia y prudencia. A menos que se especifique lo
                        contrario, no divulgaremos ni proporcionaremos esta información a
                        terceros sin
                        su permiso previo. Podemos actualizar esta política de privacidad de vez
                        en
                        cuando, una vez que acepta esta política de privacidad, se considera que
                        ha
                        aceptado todo su contenido. La póliza incluye principalmente lo
                        siguiente:</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:
9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>1.Digital Moneyboxmx Para solicitar un pr</span></b><b><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:
.55pt'>é</span></b><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>stamo debes cumplir con los siguientes
                            requisitos</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span
                        style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Residente
                        mexicano, 18 años o más</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Tener
                        un n</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ú</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>mero de m</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>vil e ingresos estables.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:
9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>2.Tasas de inter</span></b><b><span lang=ZH-CN
                            style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span></b><b><span
                            style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>s y tarifas</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Monto
                        del pr</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>stamo:
                        MXN 5,000 a MXN 15,000;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Duraci</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:
.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>n: 91 a 240 d</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>as</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Tasa
                        de inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s anual:
                        La tasa de inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:
SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s anual m</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>á</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>xima es del 22%</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Tasa
                        de inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s diaria:
                        Tasa de inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s diaria
                        0,06%</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='margin-left:0in;text-align:left;
text-indent:0in'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>C</span></b><b><span lang=ZH-CN
                            style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>á</span></b><b><span
                            style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>lculos de ejemplo de pr</span></b><b><span lang=ZH-CN
                            style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span></b><b><span
                            style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>stamo</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Por
                        ejemplo,para un pr</span><span lang=ZH-CN style='font-size:9.0pt;font-family:
SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>stamo de
                        MXN $10,000 con una tasa de inter</span><span lang=ZH-CN style='font-size:9.0pt;
font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>s anual del 22% (0.06% por d</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>a por d</span><span lang=ZH-CN style='font-size:9.0pt;
font-family:SimSun;color:black;letter-spacing:.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>a), un plazo de pr</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>stamo de 120 d</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>as (4 meses) :</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s diario
                        = MXN$10,000x0.06% = $6</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s Mensual
                        = MXN$6x30 = $180</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Pago Mensual = MXN$10,000 / 4 + $180 = $2680</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Pr</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;
letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>stamos con vencimiento en 120 d</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:
.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>as</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Su inter</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>s total
                        =MXN $180x4 = $720</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>-
                        Su pago total = MXN$10,000 + MXN$ 720 = $10720</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>4.Información clave que recopilamos</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Informaci</span></b><b><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span></b><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>n de imagen: </span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        utiliza nuestros servicios de aplicaciones financieras, Digital
                        Moneyboxmx
                        necesita acceder a su galer</span><span lang=ZH-CN style='font-size:9.0pt;
font-family:SimSun;color:black;letter-spacing:.55pt'>í</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>a para cargar im</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>á</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>genes que faciliten la comunicaci</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>n con el servicio al cliente. Tenga la seguridad de que
                        toda la informaci</span><span lang=ZH-CN style='font-size:9.0pt;font-family:
SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>n del
                        usuario involucrada se cifrar</span><span lang=ZH-CN style='font-size:9.0pt;
font-family:SimSun;color:black;letter-spacing:.55pt'>á</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'> y se cargar</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>á</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'> en nuestro centro de seguridad https://prepmx.digitalboxsecurity.com/
                        para su protecci</span><span lang=ZH-CN style='font-size:9.0pt;font-family:
SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>n.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información de la lista de contactos:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Lista
                        de contactos: Al utilizar los servicios de préstamos financieros de
                        Digital
                        Moneyboxmx, necesitamos acceder a su lista de contactos (recopilar y
                        cargar:
                        nombre de contacto, número de teléfono de contacto, etc.) para confirmar
                        la
                        autenticidad de su identidad para evaluar su solvencia financiera.
                        Contamos con
                        la tecnología de confidencialidad de la información más avanzada para
                        proteger
                        los datos de los usuarios contra filtraciones. Toda la información del
                        usuario
                        está protegida de forma segura en el Centro de seguridad </span><a
                        href="https://prepmx.digitalboxsecurity.com/"><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt;
text-decoration:none'>https://prepmx.digitalboxsecurity.com/.</span></a><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Digital Moneyboxmx no se comunicará con los contactos de
                        su lista de contactos, solo se utiliza para evaluaciones de préstamos
                        con
                        riesgo controlado.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Contacto
                        de emergencia: cuando solicita un pr</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>stamo utilizando Digital Moneyboxmx, necesitamos que
                        proporcione 2 contactos de emergencia (incluido el nombre, la
                        relaci</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>n y la informaci</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>n de contacto). Cuando su pr</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>stamo est</span><span lang=ZH-CN style='font-size:9.0pt;
font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'> vencido y no podamos comunicarnos con usted, nos
                        comunicaremos con el contacto de emergencia que proporcion</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'> para garantizar que el pr</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>stamo se pague a tiempo. No nos comunicaremos
                        con el contacto de emergencia si usted es localizable en persona.
                        Aseg</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ú</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;
color:black;letter-spacing:.55pt'>rese de que su persona de contacto de
                        emergencia est</span><span lang=ZH-CN style='font-size:9.0pt;font-family:SimSun;
color:black;letter-spacing:.55pt'>é</span><span style='font-size:9.0pt;
font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>
                        informada y acepte que usted proporcione su informaci</span><span lang=ZH-CN
                        style='font-size:9.0pt;font-family:SimSun;color:black;letter-spacing:.55pt'>ó</span><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>n de contacto como contacto de emergencia.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Permisos de notificación:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Para
                        que no te pierdas ninguna información importante, Digital Moneyboxmx te
                        enviará
                        notificaciones de actividad y recordatorios de pagos, etc. Si desea
                        obtener
                        esta información útil de manera oportuna, le recomendamos que mantenga
                        activados los permisos de notificación. Si no desea recibir
                        notificaciones,
                        puede desactivar este permiso en cualquier momento.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Informacion personal:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        solicita un préstamo, necesitamos obtener cierta información personal,
                        como
                        nombre, sexo, edad, etc., para verificar su identidad y garantizar la
                        seguridad
                        de su cuenta.Tomaremos las medidas técnicas y administrativas apropiadas
                        para
                        proteger la información del usuario</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información financiera:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        se apruebe su solicitud de préstamo, para emitir un préstamo, debe
                        obtener su
                        cuenta bancaria y otra información para asegurarse de que pueda recibir
                        el
                        préstamo sin problemas. Tenga la seguridad de que toda la información
                        del
                        usuario estará encriptada y protegida.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información sobre la ubicación:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        utiliza la APLICACIÓN Digital Moneyboxmx para solicitar servicios de
                        préstamo,
                        necesitamos acceder a su ubicación durante el proceso de solicitud para
                        evaluar
                        su crédito financiero y prevenir fraude financiero. </span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información de la cámara:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        solicitas servicios de préstamo, Digital Moneyboxmx requiere acceso a tu
                        cámara
                        para tomar una fotografía o verificación biométrica y confirmar que eres
                        la
                        persona que solicita el préstamo. </span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información del dispositivo:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        solicite un préstamo, obtendremos cierta información sobre el
                        dispositivo que
                        está utilizando, incluida la marca del dispositivo, la información del
                        hardware
                        de visualización, la versión del sistema operativo, el uso y el estado
                        del
                        emulador, etc. Esta información nos ayuda a comprender la funcionalidad
                        y la
                        compatibilidad de su dispositivo para garantizar que nuestras
                        aplicaciones
                        funcionen correctamente en su dispositivo, así como para prevenir el
                        fraude y
                        mantener su cuenta segura.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información de la cuenta social:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>IAl
                        utilizar Digital Moneyboxmx para solicitar un préstamo, obtendremos la
                        información de la cuenta social del usuario para verificar la identidad
                        del
                        prestatario, evaluar posibles riesgos y prevenir el fraude. Nos
                        aseguraremos de
                        que tu información personal no sea revelada, alquilada ni vendida a
                        terceros.
                        La información recopilada no será revelada a terceros.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>Información del identificador de publicidad:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Cuando
                        utiliza nuestra aplicación, Digital Moneyboxmx requiere acceso a su IDFA
                        para
                        poder realizar la atribución de anuncios y brindarle opciones de crédito
                        financiero más adecuadas. </span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>5.Cómo usamos la información que recopilamos</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Usaremos
                        la información recopilada para los siguientes propósitos:</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(1)
                        Para brindarle servicios, atención al cliente y otras funciones
                        necesarias para
                        el procesamiento de pedidos y las operaciones diarias.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(2)
                        Realizar análisis de datos para mejorar la calidad de nuestro
                        servicio.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(3)
                        Evaluar y desarrollar nuevos servicios y productos.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(4)
                        Proporcionarle información sobre nuestros servicios, incluidas
                        confirmaciones
                        de pedidos y notificaciones de pago.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(5)
                        Para permitirle participar en ciertas actividades de
                        comunicación.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(6)
                        Obtener información de contacto en caso de emergencia.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(7)
                        Utilizar la información para otros fines acordados por usted de
                        antemano.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>6. Finalidad de la recogida de datos personales</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Para
                        garantizar propósitos legales, legítimos, necesarios, específicos y
                        claros,
                        solo recopilaremos y utilizaremos información personal dentro del tiempo
                        requerido. Una vez que se exceda este período, eliminaremos o
                        anonimizaremos
                        inmediatamente su información personal.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>7. Almacenamiento y protección de información personal</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Para
                        proteger la seguridad de su información personal, hemos adoptado una
                        serie de
                        medidas de seguridad, que incluyen:</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(1)
                        Cifrado de datos: utilizamos tecnología de cifrado aceptada por la
                        industria
                        para proteger su información personal y subirla a
                        https://prepmx.digitalboxsecurity.com/
                        para protegerla, por lo que no debe preocuparse por la fuga de
                        información.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(2)
                        Control estricto de acceso a los datos: controlamos estrictamente los
                        derechos
                        de acceso a los datos, y solo los empleados autorizados pueden acceder a
                        su
                        información personal, y solo se utiliza para los fines
                        anteriores.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>(3)
                        Auditoría de acceso a datos: Realizamos auditorías de acceso a datos e
                        imponemos las sanciones correspondientes a los empleados que usan o
                        divulgan
                        ilegalmente su información.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Al
                        acceder a nuestros servicios a través de Internet, comprenda que, debido
                        a
                        limitaciones técnicas y posibles medios maliciosos, en la industria de
                        Internet, a pesar de nuestros mejores esfuerzos para fortalecer las
                        medidas de
                        seguridad, no siempre se puede garantizar el 100 % de la seguridad de la
                        información. Haremos todo lo posible para garantizar la seguridad de la
                        información personal que nos proporcione.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>8.Para cambiar o eliminar tu cuenta:</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Si
                        has registrado una cuenta personal en nuestra aplicación, puedes iniciar
                        sesión
                        en nuestra aplicación para verla. Registraremos tu información y la
                        encriptaremos
                        para protección. Si deseas utilizar nuestros servicios después de la
                        eliminación, por favor regístrate nuevamente. No tienes que preocuparte
                        por la
                        filtración de tu información al eliminarla. Si deseas eliminar tu
                        información
                        personal, por favor contáctanos a través de la dirección de correo
                        electrónico:
                        customer@digitalmoneyboxmx.com.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>9.Cambios en la Política de Privacidad</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Con
                        el fin de brindarle mejores servicios, esta Política de privacidad puede
                        ajustarse en consecuencia a medida que cambia nuestro negocio.
                        Revisaremos la
                        Política de privacidad mediante la publicación de una versión
                        actualizada en el
                        sitio web, y le recomendamos que visite nuestro sitio web a tiempo para
                        conocer
                        la última versión de la Política de privacidad.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>&nbsp;</span></p>

                <p class=MsoNormal align=left style='text-align:left'><b><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;
letter-spacing:.55pt'>10. Contáctenos</span></b></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif;color:black;letter-spacing:.55pt'>Si
                        tiene alguna pregunta o inquietud sobre esta Política de privacidad, o
                        si
                        necesita consultar o solicitar información sobre el procesamiento de sus
                        datos
                        personales, contáctenos en customer@digitalmoneyboxmx.com.</span></p>

                <p class=MsoNormal align=left style='text-align:left'><span style='font-size:9.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

            </div>
        </div>
    </div>
</template>

<script>
//import {  } from ''
export default {
    name: 'componentName',
    props: [],
    components: {},
    data() {
        return {

        }
    },
    mounted() {

    },
    created() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less'>
.policybox {
    padding: 4vw;
    font-size: .7rem;
}

h1 {
    text-align: center;
    font-size: 0.6rem;
    margin: 3vw 0;
}

h2 {
    font-size: .8rem;
    margin: 2vw 0 1vw;
}
</style>